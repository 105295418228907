import React, { useState } from 'react';
import Table from 'rc-table'

import images from '../ImportPaths/Imports';

export default function Main() {
   return (
      <div className="price_table second-one">
          <img className="decor_1" src={images.decor} />
          <img className="decor_2" src={images.decor} />
          <h4>Клуб</h4>
          <Table className="table_game two pc" columns={columns_arena_2} data={arena_2} />
          <GameTable />
          <Table className="table_pocket" columns={columns_tv_ps} data={tv_ps} />
      </div>
   )
}

function GameTable(){
  const [game_type, setGameType] = useState('STANDART');

  const changeGame = (event, type)=>{
    let all_actives = document.querySelectorAll('.second-one ._game .active');
    all_actives.forEach((el)=>{
      el.classList.remove('active');
    })
    event.target.classList.add('active');
    setGameType(type)
  }

  return (
    <div>
      <div className="mobile table_btns _game">
        <button onClick={(e) => {changeGame(e, 'STANDART')}} className="standart active">STANDART</button>
        <button onClick={(e) => {changeGame(e, 'VIP')}}  className="vip">VIP</button>
        <button onClick={(e) => {changeGame(e, 'BOOTCAMP')}}  className="vip">BOOTCAMP</button>
      </div>
      <ShowStandartGame gametype={game_type} />
      <ShowVipGame gametype={game_type} />
      <ShowBootcampGame gametype={game_type} />
    </div>
  )
}

function ShowStandartGame(props){
  if(props.gametype=="STANDART"){
    return (
      <Table className="table_game mobile" columns={columns_arena_standart_2} data={arena_2} />
    )
  }
}
function ShowVipGame(props){
  if(props.gametype=="VIP"){
    return (
      <Table className="table_game mobile" columns={columns_arena_vip_2} data={arena_2} />
    )
  }
}
function ShowBootcampGame(props){
  if(props.gametype=="BOOTCAMP"){
    return (
      <Table className="table_game mobile" columns={columns_arena_boot_2} data={arena_2} />
    )
  }
}

const NameComponent = ({ day }) => (
  <p dangerouslySetInnerHTML={{ __html: day }} />
);

const columns_arena_2 = [
    {
      title: 'ИГРА',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'STANDART',
      dataIndex: '',
      key: '',
      className: 'blago_white center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      className: 'marding',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_vip',
      key: 'weekday_vip',
      className: 'blago_red left',
    },
    {
      title: 'VIP',
      className: 'decor_cell',
      className: 'blago_red center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_vip',
      key: 'weekend_vip',
      className: 'last_title',
      className: 'blago_red right',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      className: 'marding',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_boot',
      key: 'weekday_boot',
      className: 'blago_red left',
    },
    {
      title: 'BOOTCAMP',
      className: 'decor_cell',
      className: 'blago_red center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_boot',
      key: 'weekend_boot',
      className: 'last_title',
      className: 'blago_red right',
    }
  ];
  const columns_arena_standart_2 = [
    {
      title: 'ИГРА',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    },
  ];
  const columns_arena_vip_2 = [
    {
      title: 'ИГРА',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_vip',
      key: 'weekday_vip',
      className: 'blago_red left',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_vip',
      key: 'weekend_vip',
      className: 'last_title',
      className: 'blago_red right',
    },
  ];
  const columns_arena_boot_2 = [
    {
      title: 'ИГРА',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_boot',
      key: 'weekday_bot',
      className: 'blago_red left',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_boot',
      key: 'weekend_boot',
      className: 'last_title',
      className: 'blago_red right',
    }
  ];
  const arena_2 = [
    { game: '', weekday: '', weekend: '', weekday_vip: '', weekend_vip: '',weekday_boot: '', weekend_boot: ''},
    { game: '1 ЧАС', weekday: '5-6 BYN', weekend: '5-7 BYN', weekday_vip: '5-7 BYN', weekend_vip: '6-8 BYN', weekday_boot: '6-8 BYN', weekend_boot: '7-9 BYN'},
    { game: '3 ЧАСА', weekday: '10-21 BYN', weekend: '12-18 BYN', weekday_vip: '12-18 BYN', weekend_vip: '15-21 BYN',weekday_boot: '14-18 BYN', weekend_boot: '18-21 BYN'},
    { game: '5 ЧАСОВ', weekday: '14-21 BYN', weekend: '17-25 BYN', weekday_vip: '17-24 BYN', weekend_vip: '21-28 BYN',weekday_boot: '20-24 BYN', weekend_boot: '25-28 BYN'},
    { game: '8 ЧАСОВ', weekday: '24 BYN', weekend: '32 BYN', weekday_vip: '27 BYN', weekend_vip: '35 BYN',weekday_boot: '27 BYN', weekend_boot: '35 BYN'},
  ];

  const columns_tv_ps = [
    {
      title: 'TV/PS5',
      dataIndex: 'day',
      key: 'day',
      className: 'first_title',
      render: (text) => <NameComponent day={text} />,
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'STANDART',
      dataIndex: '',
      key: '',
      className: 'blago_white center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    }
  ];
    const tv_ps = [
    { day: '', time: '', weekday: '', weekend: ''},
    { day: '1 ЧАС', weekday: '10 BYN', weekend: '10 BYN'},
    { day: '3 ЧАСА', weekday: '25 BYN', weekend: '25 BYN'},
    ];
