import React from 'react'
import { Link } from 'react-router-dom';

import images from '../ImportPaths/Imports';

export default function Footer() {
  return (
    <section className="arena">
          <img className="decor" src={images.decor_4} />
          <img className="decor" src={images.decor_4} />
          <img className="decor" src={images.decor_5} />
          <img className="decor" src={images.decor_6} />
          <h3>КИБЕР АРЕНА</h3>
          <div className="arena_text">Представляем вам самую крупную кибер арену в Бресте.<br/>
          На нашей площадке можно организовать турнир по различным дисциплинам от DOTA2 до CS2</div>
          <div className="arena_content">
            <div className="arena_content_wrapper">
              <div className="arena_list">
                <div className="arena_block">
                  <img src={images.pin_2} />
                  <p>51 ПК, 4 PS5</p>
                </div>
                <div className="arena_block">
                  <img src={images.pin_1} />
                  <p>300 кв. метров<br/><span>КИБЕРСПОРТА В ЦЕНТРЕ БРЕСТА</span> </p>
                </div>
                <div className="arena_block">
                  <img src={images.pin_4} />
                  <p>3 VIP-КОМНАТЫ</p>
                </div>
              </div>
              <div className="arena_link">
              <Link to="/arena">ПОДРОБНЕЕ</Link>
              </div>
            </div>
          </div>
        </section>
  )
}
