import axios from 'axios';

import { API_USERNAME, API_PASSWORD, API_SENDER, API_SMS_URL } from './configuration/config';

export function generateCode() {
    return Math.floor(10000 + Math.random() * 90000).toString();
}

export async function verifyPhone(phone) {
    const code = generateCode();

    const url = `${API_SMS_URL}/api/v1/send_sms/plain?user=${API_USERNAME}&password=${API_PASSWORD}&recipient=${phone}&message=Ваш код подтверждения: ${code}&sender=${API_SENDER}`;

    try {
        const result = await axios.get(url);

        if (result.data.error) {
            throw new Error(result.data.error_messages.join(', ') || 'Неизвестная ошибка');
        }
        return code;

    } catch (e) {
        return null;
    }
}
