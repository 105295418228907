import React from 'react'
import images from '../../components/ImportPaths/Imports';

export default function Footer() {
  return (
    <footer class="admin-footer">
        <div className="production">
          <a href="https://www.linkedin.com/company/blxseed-product/" target="_blank" rel="noopener noreferrer">
              BLXSSED PRODUCT
              <img src={images.cog} alt="Cog" />
            </a>
        </div>
        <p>
            Все права защищены - 2025
        </p>
    </footer>
  )
}
