import React, { useEffect, useState } from 'react';
import axios from "axios";
import images from '../ImportPaths/Imports';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Main() {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let path = window.location.pathname.split('/');
            let id = path[path.length - 1];
            try {
                const response = await axios.get('/api/post/' + id);
                setPost(response.data);
            } catch (err) {
                console.error('Error fetching post:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return (
        <section className="single_news">
            <img className="loading" src={images.loading_gif} alt="Loading..." />
        </section>
    );

    if (!post.currentTimestamp) {
        return <section className="single_news"><div>Error: Post data is not available.</div></section>;
    }
    console.log('Raw Timestamp:', post.currentTimestamp);

    const trimmedTimestamp = post.currentTimestamp.trim();
    const dateUTC = new Date(trimmedTimestamp);
    const localDate = new Date(dateUTC.getTime() + (3 * 60 * 60 * 1000));
    console.log('Local Date Object:', localDate);

    if (isNaN(localDate.getTime())) {
        return <div>Error: Invalid Date</div>;
    }

    const formattedDate = localDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
    });

    return (
        <section className="single_news">
            <div className="single_news_wrap">
                <div className="snw_preview">
                    {post.cover && (
                        <img 
                            src={`data:image/${post.cover.type};base64,${post.cover.image}`} 
                            alt="Cover" 
                        />
                    )}
                    <div className="snw_title">
                        <h3>{post.title}</h3>
                        <span>{formattedDate}</span>
                    </div>
                </div>
                <div className="snw_content">
                    {post.content}
                </div>
            </div>
        </section>
    );
}
