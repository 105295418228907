import React from 'react'
import { Link } from 'react-router-dom';

import images from '../ImportPaths/Imports';

export default function Main() {
    return (
        <section className="game">
          <h3>ИГРЫ И ОБОРУДОВАНИЕ</h3>
          <div className="game_text">Только лицензионные игры с возможностью подключения вашего аккаунта
          и участия в турнирах на нашей Арене</div>
          <div className="game_slider">
            <img src={images.game_1} />
            <img src={images.game_2} />
            <img src={images.game_3} />
            <img src={images.game_4} />
            <Link to="/games">
              <img src={images.game_5} />
            </Link>
          </div>
        </section>
    )
}
