import { Link } from 'react-router-dom';

import PriceArena from '../components/sections/PriceArena'
import images from '../components/ImportPaths/Imports';

export default function ArenaPage() {
    return (
        <div className="arena_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Киберспортивная арена AVATAR в Бресте </p>
                    <h3>АРЕНА</h3>
                </div>
            </section>
            <section className="arena_page_content">
                <div className="arena_content">
                    <div className="arena_content_wrapper">
                        <div className="arena_list">
                            <div className="arena_block">
                            <img src={images.pin_2} />
                            <p>51 ПК, 4 PS5</p>
                            </div>
                        </div>
                        <div className="comp_wrapper">
                            <div className="comp_content">
                                <p>Представляем вам самую крупную кибер арену в Бресте.
                                На нашей площадке можно организовать турнир по различным
                                дисциплинам от СS2 до DOTA2</p>
                            </div>
                            <div className="comp_address">
                                <a href="tel:+375295485555">+375295485555</a>
                                <p>г. Брест, ул. Советская, 71/2</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="arena_page_titles">
                <div className="titles_wrapper">
                    <div className="titles_block">
                        <h4>Огромная</h4>
                        <p>вместительность</p>
                    </div>
                    <div className="titles_block">
                        <h4>Удобная</h4>
                        <p>локация</p>
                    </div>
                    <div className="titles_block">
                        <h4>Большой</h4>
                        <p>выбор еды и напитков</p>
                    </div>
                    <div className="titles_block">
                        <h4>Лучшее</h4>
                        <p>оборудование</p>
                    </div>
                    <div className="titles_block">
                        <h4>Круглосуточное</h4>
                        <p>время работы</p>
                    </div>
                </div>
            </section>
            <section className="zones">
                <img className="decor" src={images.decor} />
                <div className="zones_wrapper">
                    <h3>ИГРОВЫЕ ЗОНЫ</h3>
                    <div className="zones_blocks_wrapper">
                        <div className="zones_block">
                            <img src={images.zone_1} />
                            <h5>Lounge зона с приставками</h5>
                        </div>
                        <div className="zones_block">
                            <img src={images.zone_2} />
                            <h5>VIP PS5 ROOMS</h5>
                        </div>
                        <div className="zones_block">
                            <img src={images.zone_3} />
                            <h5>VIP PC ROOM</h5>
                        </div>
                    </div>
                </div>
            </section>
            <h4 className="club_table_title">ЦЕНЫ</h4>
            <PriceArena />
        </div>
    )
}
