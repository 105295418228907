import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from "./Pages/Footer";
import Header from "./Pages/Header";
import Main from "./Pages/Main";
import About from "./Pages/About";
import Price from "./Pages/Price";
import LatestNews from "./Pages/LatestNews";
import Games from "./Pages/Games";
import Contact from "./Pages/Contacts";
import Rule from "./Pages/Rules";
import AVArena from "./Pages/AVArena";
import AVClub from "./Pages/AVClub";
import Auth from "./Profiles/Auth";
import My from "./Profiles/My";
import SingleNewsPage from "./components/news/SingleNewsPage";
import Tournament from "./Pages/Tournament";
import PostPanel from "./AdminPanel/PostPanelAuth";
import CreatePostPanel from "./AdminPanel/CreatePostPanel";
import { AuthProvider } from "./AdminAuth/AuthContext";
import ProtectedRoute from "./AdminAuth/ProtectedRoute";
import ErrorPage from './ErrorPage/ErrorPage';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function Layout({ children }) {
    return (
        <div id="top">
            <Header />
            {children}
            <Footer />
        </div>
    );
}

function ErrorLayout({ children }) {
    return (
        <div id="top">
            <Header />
            {children}
        </div>
    );
}

function App() {
    return (
        <AuthProvider>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout><Main /></Layout>} />
                    <Route path="/about" element={<Layout><About /></Layout>} />
                    <Route path="/price" element={<Layout><Price /></Layout>} />
                    <Route path="/news" element={<Layout><LatestNews /></Layout>} />
                    <Route path="/games" element={<Layout><Games /></Layout>} />
                    <Route path="/contacts" element={<Layout><Contact /></Layout>} />
                    <Route path="/rules" element={<Layout><Rule /></Layout>} />
                    <Route path="/arena" element={<Layout><AVArena /></Layout>} />
                    <Route path="/club" element={<Layout><AVClub /></Layout>} />
                    <Route path="/tournament" element={<Layout><Tournament /></Layout>} />
                    <Route path="/auth" element={<Layout><Auth /></Layout>} />
                    <Route path="/my" element={<Layout><My /></Layout>} />
                    <Route path="/admin" element={<PostPanel />} />
                    <Route path="/create" element={<ProtectedRoute><CreatePostPanel /></ProtectedRoute>} />
                    <Route path="/news/:id" element={<Layout><SingleNewsPage /></Layout>} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorLayout><ErrorPage location={{ state: { errorCode: 404, message: 'Страница не найдена' } }} /></ErrorLayout>} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
