import React from 'react'
import { Link } from 'react-router-dom';

import images from '../components/ImportPaths/Imports';

export default function Footer() {
  return (
    <footer>
      <div className="footer_wrapper">
        <div className="social">
          <img src={images.logo_com} className="logo" />
          <div className="socials">
            <a href="https://vk.com/avatarhub"><img src={images.vk}/></a>
            <a href="#"><img src={images.ds}/></a>
            <a href="https://www.tiktok.com/@avatararenabrest?_t=8oaePRTIC73&_r=1"><img src={images.tt}/></a>
            <a href="https://t.me/+vqv0kOGIExM5YWIy"><img src={images.tg}/></a>
            <a href="https://t.me/AVTournamentsBot"><img src={images.tb}/></a>
            <a href="https://www.instagram.com/avatar.arena.brest/"><img src={images.inst}/></a>
          </div>
          <a className="mail" href="mailto:avatar.brest@gmail.com">avatar.brest@gmail.com</a>
        </div>
        <div className="links">
          <Link to="/arena">АРЕНА</Link>
          <Link to="/price">ЦЕНЫ</Link>
          <Link to="/news">НОВОСТИ</Link>
          <Link to="/about">О НАС</Link>
        </div>
        <div className="links">
          <Link to="/club">КЛУБ</Link>
          <Link to="/games">ИГРЫ</Link>
          <Link to="/contacts">ЗАБРОНИРОВАТЬ</Link>
          <Link to="/rules">ПРАВИЛА КЛУБА</Link>
        </div>
        <div className="address">
          <h5>АРЕНА: +375 (29) 548 55 55</h5>
          <div class="address_text">
            <p>г. Брест, ул. Советская, 71/2</p>
            <a href="tel:+375295485555"><img src={images.pincall} /></a>
            <a href="https://t.me/avatararenabrest"><img src={images.pintg} /></a>
          </div>
          <h5>КЛУБ: +375 (29) 222 22 14</h5>
          <div className="address_text">
            <p>г. Брест, ул. Пушкинская, 23</p>
            <a href="tel:+375292222214"><img src={images.pincall} /></a>
            <a href="https://t.me/avatar_cyberclub"><img src={images.pintg} /></a>
          </div>
        </div>
      </div>
      <footer class="admin-footer-main">
        <div className="production">
          <a href="https://www.linkedin.com/company/blxseed-product/" target="_blank" rel="noopener noreferrer">
            BLXSSED PRODUCT
            <img src={images.cog} alt="Cog" />
            </a>
        </div>
        <p>
        2025 AVATAR - Все права защищены &copy;
        </p>
    </footer>
    </footer>
  )
}
