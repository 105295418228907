import logo from '../img/321.png';
import cog from '../img/cog.png';
import send from '../img/send.png'
import emo from '../img/emo.png'
import edit_pic from '../img/Edit_icon.png'
import trash from '../img/Trash.png'
import loading_gif from '../img/loading.gif';
import log from '../../components/img/log.png';
import logo_com from '../../components/img/logo_com.png';
import linktobot from '../../components/img/linktobot.png';
import decor_4 from '../../components/img/dash_second.png'
import decor_5 from '../../components/img/dash_eighth.png'
import decor_6 from '../../components/img/dash_seventh.png'
import pin_1 from '../../components/img/pin-1.png'
import pin_2 from '../../components/img/pin-2.png'
import pin_4 from '../../components/img/VIP.png'
import pin_3 from '../../components/img/pin-3.png'
import game_1 from '../../components/img/game_cs2.jpg'
import game_2 from '../../components/img/game_gta5.jpg'
import game_3 from '../../components/img/game_hell.jpg'
import game_4 from '../../components/img/game_forza.jpg'
import game_5 from '../../components/img/link_to_ul.jpg'
import decor from '../../components/img/dash_first.png';
import decor_1 from '../../components/img/dash_tenth.png'
import decor_3 from '../../components/img/dash_eleventh.png';
import block_1 from '../../components/img/cfgicons/monitor.png'
import block_2 from '../../components/img/cfgicons/videocard.png'
import block_3 from '../../components/img/cfgicons/processor.png'
import block_4 from '../../components/img/cfgicons/memory.png'
import block_5 from '../../components/img/cfgicons/mouse.png'
import block_6 from '../../components/img/cfgicons/keyboard.png'
import block_7 from '../../components/img/cfgicons/headpones.png'
import block_8 from '../../components/img/cfgicons/chair.png'
import about from '../../components/img/about.jpg'
import zone_1 from '../../components/img/zone1.jpg'
import zone_2 from '../../components/img/zone2.jpg'
import zone_3 from '../../components/img/zone3.jpg'
import tg from '../../components/img/tg.png'
import inst from '../../components/img/in.png'
import vk from '../../components/img/vk.png'
import tt from '../../components/img/tt.png'
import tb from '../../components/img/tb.png'
import ds from '../../components/img/ds.png'
import pincall from '../../components/img/pin-call.png'
import pintg from '../../components/img/pin-telega.png'
import game_cs from '../../components/img/game_cs2_secondv.jpg'
import game_dota from '../../components/img/game_dota2.jpg'
import game_pubg from '../../components/img/game_pubg.jpg'
import game_fort from '../../components/img/game_fort.jpg'
import game_val from '../../components/img/game_valorant.jpg'
import game_gta from '../../components/img/game_gta5.jpg'
import game_cod from '../../components/img/game_cod.jpg'
import game_rust from '../../components/img/game_rust.jpg'
import game_wow from '../../components/img/game_wow.jpg';
import game_hell from '../../components/img/game_hell.jpg';
import time from '../../components/img/dash_worktime.png';
import menu from '../../components/img/menu.png';
import vid from '../../components/img/vid_club.mp4';
import decor_2 from '../../components/img/dash_fouth.png';
import tour_1 from '../../components/img/tour_2.jpg';
import tour_2 from '../../components/img/tour_3.jpg';
import tour_3 from '../../components/img/tour_4.jpg';
import gamepad from '../../components/img/gamepad.png';
import avatar from '../../components/img/avatar.jpg';
import edit from '../../components/img/edit.png';
import refresh from '../../components/img/refresh.png';
import slide1 from '../../components/img/new_slide_1.jpg';
import slide2 from '../../components/img/new_slide_2.jpg';
import slide3 from '../../components/img/new_slide_3.jpg';
import slide4 from '../../components/img/new_slide_4.jpg';
import fallbackImage from '../../components/img/dontdeletethisimage.jpg'

const images = {
    logo,
    cog,
    send,
    emo,
    edit_pic,
    trash,
    loading_gif,
    log,
    logo_com,
    linktobot,
    decor_4,
    decor_5,
    decor_6,
    pin_1,
    pin_2,
    pin_3,
    pin_4,
    game_1,
    game_2,
    game_3,
    game_4,
    game_5,
    decor_3,
    decor,
    block_1,
    block_2,
    block_3,
    block_4,
    block_5,
    block_6,
    block_7,
    block_8,
    about,
    decor_1,
    zone_1,
    zone_2,
    zone_3,
    tg,
    inst,
    vk,
    tt,
    tb,
    ds,
    pincall,
    pintg,
    game_cs,
    game_dota,
    game_pubg,
    game_fort,
    game_val,
    game_gta,
    game_rust,
    game_cod,
    game_wow,
    game_hell,
    time,
    menu,
    vid,
    decor_2,
    tour_1,
    tour_2,
    tour_3,
    gamepad,
    avatar,
    edit,
    refresh,
    slide1,
    slide2,
    slide3,
    slide4,
    fallbackImage
};

export default images;
