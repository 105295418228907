import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { verifyPhone } from '../PhoneVerification/VerifyPhone';
import images from '../components/ImportPaths/Imports';

const types = ["ARENA", "CYBERCLUB"];
const api_login = process.env.REACT_APP_GIZMO_API_LOGIN;
const api_pass = process.env.REACT_APP_GIZMO_API_PASSWORD;

let token = '';

export default function Main() {
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId) {
            window.location.href = '/my';
        }
    }, []);

    return (
        <div className="auth_page_wrapper">
            <video
                className="auth_video"
                autoPlay
                muted
                loop
                playsInline
                onLoadedMetadata={handlePlay}
                src={images.vid}
            />
            <div className="auth_video_overlay" />
            <div className="auth_content">
                <SwipeAuth />
            </div>
        </div>
    );
}

function SwipeAuth() {
    const [authType, setAuthType] = useState('login');

    const changeAuth = (event, type) => {
        document.querySelectorAll('.auth_header button').forEach((el) => {
            el.classList.remove('active');
        });
        event.target.classList.add('active');
        setAuthType(type);
    };

    return (
        <div className="auth_body">
            <div className="auth_form">
                <LoginWindow authType={authType} />
                <RegWindow authType={authType} />
            </div>
            <div className="auth_header">
                <button onClick={(e) => changeAuth(e, 'login')} className="active">Войти</button>
                <img src={images.gamepad}/>
                <button onClick={(e) => changeAuth(e, 'register')}>Регистрация</button>
            </div>
        </div>
    );
}

function LoginWindow({ authType }) {
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');

    const handleLogin = (value) => setLogin(value);
    const handlePass = (value) => setPass(value);

    const AuthLogin = async () => {
        if (!login || !pass) {
            showMessage(false, "Введите данные");
            return;
        }

        const option = document.querySelector('.auth_type');
        axios.defaults.baseURL = option.value === types[0]
            ? process.env.REACT_APP_GIZMO_ARENA_API_URL
            : process.env.REACT_APP_GIZMO_CLUB_API_URL;
        sessionStorage.setItem('api_adress', option.value);

        try {
            await axios.get(`/api/user/v2.0/auth/accesstoken?Username=${login}&Password=${pass}`);
            await loginIn(option.value);
            await getUserIdByLogin(login);
        } catch (e) {
            showMessage(false, "Пользователь не найден");
        }
    };

    if (authType !== "login") return null;

    return (
        <div className="login_wrapper">
            <label>Выберите клуб:</label>
            <select className="auth_type">
                <option value={types[0]}>{types[0]}</option>
                <option value={types[1]}>{types[1]}</option>
            </select>
            <input type="text" value={login} onChange={el => handleLogin(el.target.value)} placeholder='Логин' />
            <input type="password" value={pass} onChange={el => handlePass(el.target.value)} placeholder='Пароль' />
            <button onClick={AuthLogin} className="auth_submit">Авторизироваться</button>
        </div>
    );
}

function RegWindow({ authType }) {
    if (authType !== "register") return null;

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [phone, setPhone] = useState('');
    const [accept, setAccept] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [generatedCode, setGeneratedCode] = useState('');
    const [inputCode, setInputCode] = useState('');
    const [selectedType, setSelectedType] = useState(types[0]);
    const [phoneError, setPhoneError] = useState('');
    const [timer, setTimer] = useState(0);

    const validatePhone = (phone) => {
        const phonePattern = /^\+375\s?\d{2}\s?\d{3}-?\d{2}-?\d{2}$/;
        return phonePattern.test(phone);
    };

    const handleRegistration = async () => {
        if (!login || !pass || !phone) {
            showMessage(false, "Введите данные");
            return;
        }
        if (!accept) {
            showMessage(false, "Требуется соглашение");
            return;
        }

        if (!validatePhone(phone)) {
            showMessage(false,"Правильный формат: +375...");
            return;
        } else {
            setPhoneError('');
        }

        const code = await verifyPhone(phone);
        if (!code) return;

        setGeneratedCode(code);
        setIsCodeSent(true);
        setTimer(60);

        showMessage(true, "Код подтверждения отправлен на ваш телефон.");
    };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    const verifyCode = async () => {
        if (inputCode === generatedCode) {
            await registerUser();
        } else {
            showMessage(false, "Неверный код подтверждения. Попробуйте снова.");
        }
    };

    const requestNewCode = async () => {
        if (timer === 0) {
            handleRegistration();
        } else {
            showMessage(false, `Пожалуйста, подождите ${timer} секунд перед повторной отправкой кода.`);
        }
    };

    const registerUser = async () => {
        if (!login || !pass || !phone) {
            showMessage(false, "Введите данные");
            return;
        }

        axios.defaults.baseURL = selectedType === types[0]
            ? process.env.REACT_APP_GIZMO_ARENA_API_URL
            : process.env.REACT_APP_GIZMO_CLUB_API_URL;

        sessionStorage.setItem('api_adress', selectedType);

        try {
            await loginIn(selectedType);
            const response = await axios.post(`/api/v2.0/users`, {
                password: pass,
                username: login,
                userGroupId: 2,
                isNegativeBalanceAllowed: true,
                isPersonalInfoRequested: true,
                mobilePhone: phone
            }, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            sessionStorage.setItem('userId', response.data.result.id);
            sessionStorage.setItem('token', token);
            window.location.href = '/my';
        } catch (e) {
            if (e.response && e.response.status === 409) {
                showMessage(false, "Ошибка регистрации: аккаунт с таким номером уже существует.");
            } else {
                showMessage(false, "Ошибка регистрации: " + e.message);
            }
        }
    };

    return (
        <div className="reg_wrapper">
            {!isCodeSent ? (
                <>
                    <label>Выберите клуб:</label>
                    <select
                        className="auth_type"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                        <option value={types[0]}>{types[0]}</option>
                        <option value={types[1]}>{types[1]}</option>
                    </select>
                    <label>Придумайте уникальный логин:</label>
                    <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} placeholder='Логин' />
                    <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='+375 __ ___-__-__' />
                    {phoneError && <div className="error">{phoneError}</div>}
                    <input type="password" value={pass} onChange={(e) => setPass(e.target.value)} placeholder='Пароль' />
                    <div className="auth_input_wrapper">
                        <label>
                            Согласен с <a target="_blank" rel="noopener noreferrer" href="https://avatararena.by/rules">правилами</a> обработки персональных данных:
                        </label>
                        <input type="checkbox" checked={accept} onChange={(e) => setAccept(e.target.checked)} />
                    </div>
                    <button onClick={requestNewCode} className="auth_submit">Зарегистрироваться</button>
                </>
            ) : (
                <div className="code_input_wrapper">
                    <input type="text" value={inputCode} onChange={(e) => setInputCode(e.target.value)} placeholder='Введите код подтверждения' />
                    <button onClick={verifyCode} className="auth_submit">Подтвердить код</button>
                    {timer > 0 && <div>Повторно отправить код через {timer} секунд.</div>}
                    <button onClick={requestNewCode} className="auth_submit">Отправить код снова</button>
                    <button onClick={() => setIsCodeSent(false)} className="auth_submit cancel_button">Отменить</button>
                </div>
            )}
        </div>
    );
}

function showMessage(success, text) {
    const authPage = document.querySelector('.auth_page_wrapper');
    const message = document.createElement('div');
    message.className = success ? 'auth_success' : 'auth_error';
    message.innerText = text;
    authPage.appendChild(message);
    setTimeout(() => {
        authPage.removeChild(message);
    }, 2000);
}

async function getUserIdByLogin(login) {
    try {
        const response = await axios.get(`/api/users/${login}/userid`, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        showMessage(true, "Авторизация выполнена");
        sessionStorage.setItem('userId', response.data.result);
        sessionStorage.setItem('token', token);
        window.location.href = '/my';
    } catch (e) {
        showMessage(false, "Пользователь не найден");
    }
}

async function loginIn(type) {
    const url = type === types[0]
        ? `${process.env.REACT_APP_GIZMO_ARENA_API_URL}/auth/token?username=${api_login}&password=${api_pass}`
        : `${process.env.REACT_APP_GIZMO_CLUB_API_URL}/auth/token?username=${api_login}&password=${api_pass}`;

    try {
        const response = await axios.get(url, { headers: { accept: 'application/json' } });
        if (response.data.token) {
            token = response.data.token;
        }
    } catch (e) {
        showMessage(false, "Не удалось установить соединение");
    }
}
