import React, { useState, createContext, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = (email, password) => {
        const validEmail = process.env.REACT_APP_ADMIN_PANEL_LOGIN;
        const validPassword = process.env.REACT_APP_ADMIN_PANEL_PASS;

        if (email === validEmail && password === validPassword) {
            setIsAuthenticated(true);
            sessionStorage.setItem('auth','yes');
            return true;
        }
        return false;
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
