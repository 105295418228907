import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import { verifyPhone } from '../PhoneVerification/VerifyPhone';

import images from '../components/ImportPaths/Imports';

const types = ["ARENA", "CYBERCLUB"];
const personalAccountData = [
    { key: 'STANDARD', number: 2 },
    { key: 'С аккаунтом клуба', number: 3 },
    { key: 'Старожила', number: 6 },
    { key: 'AVATAR+', number: 9 },
    { key: 'AVATAR PRO', number: 10 },
    { key: 'AVATAR BASIC', number: 11 }
];
const add = [images.slide4, images.slide1, images.slide2, images.slide3];

export default function Main() {
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState('');
    const [changedName, setChangedName] = useState('');
    const [createdAccTime, setCreatedAccTime] = useState('');

    const [pockets, setPockets] = useState([]);
    const [userGroupId, setUserGroupId] = useState(null);
    const [userBalance, setUserBalance] = useState(0);
    const [userAVCoins, setUserAVCoins] = useState(0);

    const [avatarSrc, setAvatarSrc] = useState(images.avatar);
    const [isEditing, setIsEditing] = useState(false);

    const [isVerifying, setIsVerifying] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [generatedCode, setGeneratedCode] = useState('');
    const [timer, setTimer] = useState(0);

    const [phoneError, setPhoneError] = useState('');
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [popup, setPopup] = useState(false);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const closePopup = (e) => {
        e.stopPropagation();
        if(images.edit){
        }
        setPopup(false);
        setIsEditing(null);
    };
    const openPopup = () => {
        setPopup(true);
    };

    const changeUserName = async (newUsername) => {
        if (!newUsername) {
            showMessage(false, "Имя не может быть пустым!");
            return;
        }

        if (newUsername !== changedName) {
            try {
                setUsername(newUsername);
            } catch (error) {
                console.error('Ошибка при обновлении имени:', error);
                showMessage(false, "Произошла ошибка при обновлении имени. Пожалуйста, попробуйте еще раз.");
            }
        } else {
            console.log("memorisecodead&fedos inc.");
        }
    };

    const changePassword = async () => {
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');

        try {
            if (newPassword !== confirmPassword) {
                showMessage(false, "Пароли не совпадают!");
                return;
            }

            await axios.post(`/api/users/${userId}/password/${newPassword}`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            showMessage(true, "Пароль успешно изменён!");
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Ошибка при изменении пароля:', error);
            showMessage(false, "Ошибка при изменении пароля. Пожалуйста, попробуйте ещё раз.");
        }
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\+375\s?\d{2}\s?\d{3}-?\d{2}-?\d{2}$/;
        return phonePattern.test(phone);
    };

    const requestNewCode = async () => {
        if (timer === 0) {
            const code = await verifyPhone(phoneNumber);
            setGeneratedCode(code);
            setIsVerifying(true);
            setTimer(60);
            showMessage(true, "Код подтверждения отправлен на ваш номер телефона.");
        } else {
            showMessage(false, `Пожалуйста, подождите ${timer} секунд перед повторной отправкой кода.`);
        }
    };

    const acceptChanges = async () => {
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');
        const data = { UserId: userId };
        if (phoneNumber !== '' && phoneNumber !== originalPhoneNumber) {
            if (!validatePhone(phoneNumber)) {
                showMessage(false, "Правильный формат: +375...");
                return;
            } else {
                setPhoneError('');
            }

            if (!isVerifying) {
                const code = await verifyPhone(phoneNumber);
                setGeneratedCode(code);
                setIsVerifying(true);
                setTimer(60);
                showMessage(true, "Код подтверждения отправлен на ваш номер телефона.");
                return;
            } else {
                if (verificationCode === generatedCode) {
                    data.MobilePhone = phoneNumber;
                } else {
                    showMessage(false, "Неверный код. Пожалуйста, попробуйте снова.");
                    return;
                }
            }
        }
        if(changedName != username && username){
            data.Username = username;
        }
        if (newPassword !== confirmPassword) {
            showMessage(false, "Пароли не совпадают!");
            return;
        } else {
            if (newPassword == confirmPassword && newPassword) {
                await axios.post(`/api/users/${userId}/password/${newPassword}`, null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                showMessage(true, "Пароль изменен");
                setNewPassword('');
                setConfirmPassword('');
            }
        }
        if(Object.keys(data).length>1){
            try {
                await axios.post(`/api/users/`, null, {
                    params: data,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            } catch (error) {
                console.error('Error updating user data:', error);
                showMessage(false, 'Ошибка при обновлении данных. Пожалуйста, попробуйте еще раз.');
            } finally {
                setIsEditing(false);
                setIsVerifying(false);
                showMessage(true, "Данные изменены");
            }
        }

    };

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;
                if (img.width > 1080 || img.height > 1080) {
                    showMessage(false, 'Размеры изображения не должны превышать 1080x1080 пикселей.');
                    return;
                }

                let base64String = reader.result;

                if (base64String.startsWith('data:image/')) {
                    base64String = base64String.split(',')[1];
                }

                const userId = sessionStorage.getItem('userId');
                const token = sessionStorage.getItem('token');

                try {
                    await axios.put(`/api/v2.0/users/${userId}/picture`, {
                        picture: base64String
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setAvatarSrc(reader.result);

                    showMessage(true, 'Аватар успешно обновлён!');

                } catch (error) {
                    console.error('Error updating avatar:', error);
                    showMessage(false, `Ошибка при обновлении аватара: ${error.message}`);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');
        const address = sessionStorage.getItem('api_adress');

        if (!userId || !token) {
            window.location.href = '/auth';
            return;
        }

        axios.defaults.baseURL = address === types[0] ? process.env.REACT_APP_GIZMO_ARENA_API_URL
        : process.env.REACT_APP_GIZMO_CLUB_API_URL;

        const fetchUserData = async () => {
            try {
                const userResponse = await axios.get(`/api/users/${userId}`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUserGroupId(userResponse.data.result.userGroupId);
                setUsername(userResponse.data.result.username);
                setChangedName(userResponse.data.result.username);
                setCreatedAccTime(userResponse.data.result.createdTime);
                setPhoneNumber(userResponse.data.result.mobilePhone);
                setOriginalPhoneNumber(userResponse.data.result.mobilePhone);

                const balanceResponse = await axios.get(`/api/users/${userId}/balance`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                setUserBalance(balanceResponse.data.result.deposits);
                setUserAVCoins(balanceResponse.data.result.points);

                const productTimeResponse = await axios.get(`/api/users/${userId}/producttime`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const pockets_ = productTimeResponse.data.result.filter(item =>
                    item.totalSeconds !== item.usedSeconds && !item.isExpired && !item.isDeleted && !item.isDepleted
                );

                setPockets(pockets_);

                const response = await axios.get(`/api/v2.0/users/${userId}/picture`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const picture = response.data.result?.picture;
                if (picture) {
                    setAvatarSrc(`data:image/png;base64,${picture}`);
                } else {
                    console.warn('No picture found, using default image.');
                    setAvatarSrc(avatar);
                }

            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 401) {
                    sessionStorage.setItem('userId', '')
                    sessionStorage.setItem('token', '')
                    sessionStorage.setItem('api_adress', '')
                    alert('Ваша сессия истекла. Пожалуйста, войдите снова.');
                    window.location.href = '/auth';
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    if (loading) return (
        <section className="my_account">
            <img className="loading" src={images.loading_gif} alt="Loading..." />
        </section>
    );

    const accountStatus = personalAccountData.find(item => item.number === userGroupId)?.key
    || 'Неизвестный статус';

    return (
        <div className="my_account">
            <div className="my_body_wrapper">
                <img className="log_out_icon" onClick={logOut} src={images.log} />
                <div className="my_body">
                    <div className="my_data">
                        <div className="my_block">
                            <img className="my_avatar" src={avatarSrc} alt="Avatar" />
                            {isEditing ? (
                                <div>
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="Введите имя"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleAvatarChange}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h4 className="my_name_is">{username}</h4>
                                </div>
                            )}
                        </div>
                        <div className="my_block">
                        <label>ВАШИ ДАННЫЕ:</label>
                        {isEditing ? (
                            <div>
                                <input
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Введите номер телефона"
                                />
                                {isVerifying && (
                                    <div>
                                        <input
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            placeholder="Введите код подтверждения"
                                        />
                                        <div>Запросить новый код можно через {timer} секунд.</div>
                                        <button onClick={requestNewCode} className="auth_submit">Отправить код снова</button>
                                    </div>
                                )}
                                <button onClick={handleSave}>{isVerifying ? 'Подтвердить' : 'Сохранить'}</button>
                                <div>
                                    <h5>Изменить пароль</h5>
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Новый пароль"
                                    />
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Подтвердите пароль"
                                    />
                                    <button onClick={changePassword}>Изменить пароль</button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <PhoneNumberDisplay phoneNumber={phoneNumber} />
                                <button onClick={() => openPopup()}>
                                    <img src={images.edit} alt="Edit" />
                                </button>
                                {phoneError && <div className="error">{phoneError}</div>}
                            </div>
                        )}
                        <p>ID: {sessionStorage.getItem('userId')}</p>
                    </div>
                    <div onClick={(e) => closePopup(e)} className={popup ? 'popup-screen-profile show' : 'popup-screen-profile hide'}>
                    <div className='screen-profile-wrapper' onClick={(e) => e.stopPropagation()}>
                    <div className='username-input-wrapper' >
                        { (avatarSrc && avatarSrc.type!="Buffer")&& (
                            <img className="my_avatar" src={avatarSrc}/>
                        )}
                        { (avatarSrc && avatarSrc.type=="Buffer")&& (
                            <img src={`data:image/jpeg;base64,${Buffer.from(avatarSrc).toString('base64')}`} />
                        )}
                        <div className='profiles-inputs'>
                            <input className='username-input'
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Введите имя"
                            />
                            <div class="">
                                <label htmlFor="avatar-cover" className="select-avatar-btn">
                                    Выбрать фото
                                </label>
                                <input className='avatar-photo-input'
                                    type="file"
                                    id="avatar-cover"
                                    accept="image/*"
                                    onChange={handleAvatarChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div onClick={(e) => e.stopPropagation()} className="popup-body-profile">
                        <div>
                            <div class="popup-input-wrappers">
                                <label>Телефон:</label>
                                <input className='telephone-input'
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Введите номер телефона"
                                    />
                                </div>
                                {isVerifying && (
                                    <div class="verify-wrapper">
                                        <label>Код:</label>
                                        <input className='verify-phone-input'
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            placeholder="Введите код подтверждения"
                                            />
                                        {timer > 0 && <div class="verify-text">Запросить новый код можно через {timer} секунд.</div>}
                                        <button onClick={requestNewCode} className="verify_submit">Отправить код снова</button>
                                    </div>
                                )}
                                    <div class="pass-wrapper">
                                        <label>Пароль:</label>
                                        <div>
                                            <input className='pass-input'
                                                type="password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                placeholder="Новый пароль"
                                            />
                                            <input className='pass-input'
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Подтвердите пароль"
                                            />
                                        </div>
                                        {/* <button onClick={changePassword}>Изменить пароль</button> */}
                                    </div>
                                </div>
                                <button className="auth_submit" onClick={acceptChanges}> {isVerifying ? 'Подтвердить' : 'Сохранить'}</button>
                                <span className="close-popup-profile" onClick={(e) => closePopup(e)}>+</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="my_info">
                        <div className="my_block">
                            <p>Текущий статус: {accountStatus}</p>
                            <AccountRegistration registrationTime={createdAccTime} />
                        </div>
                        <div className="my_block balance">
                            <label>ОСНОВНОЙ СЧЕТ:</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="my_balance">{userBalance} BYN</div>
                                <div className="my_coins_balance">{userAVCoins} AVATAR Коинов</div>
                            </div>
                            <button><img src={images.refresh} alt="Refresh" /></button>
                        </div>
                    </div>
                </div>
                <div className="my_all">
                    <div className="my_pockets">
                        <div className="my_pocket_wrapper">
                            <h5>ДОСТУПНЫЕ ПАКЕТЫ</h5>
                            <h5>ОСТАЛОСЬ ВРЕМЕНИ</h5>
                        </div>
                        {pockets.length ?
                            '' :
                            <div className="my_pockets_empty">Нет доступных пакетов</div>
                        }
                        <div className="my_pocket_wrapper">
                            <div className="my_pocket_table">
                                {pockets.map((item) => (
                                    <p key={item.productName}>{item.productName.replace('*', '')}</p>
                                ))}
                            </div>
                            <div className="my_pocket_table">
                                {pockets.map((item) => (
                                    <TimeFormatter key={item.productName} seconds={item.totalSeconds - item.usedSeconds} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="my_add">
                        <SimpleImageSlider
                            images={add}
                            showBullets={true}
                            showNavs={true}
                            autoPlay={true}
                            autoPlayDelay={3.0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const formatSeconds = (totalSeconds) => {
    const date = new Date(0);
    date.setSeconds(totalSeconds);

    const hours = date.getUTCHours();
    const mins = date.getUTCMinutes();
    const formattedHours = hours > 0 ? `${hours} ч ` : '';

    return `${formattedHours}${mins} мин`;
};

const logOut = () => {
    sessionStorage.setItem('userId', '')
    sessionStorage.setItem('token', '')
    sessionStorage.setItem('api_adress', '')
    window.location.href = '/auth';
};

const TimeFormatter = ({ seconds }) => {
    return (
        <p>{formatSeconds(seconds)}</p>
    );
};

const AccountRegistration = ({ registrationTime }) => {
    const yearsAndDaysSinceRegistration = (registrationTime) => {
        const registeredDate = new Date(registrationTime);
        const now = new Date();

        const timeDifference = now - registeredDate;
        const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        const years = Math.floor(totalDays / 365);
        const days = totalDays % 365;

        return { years, days };
    };

    const { years, days } = yearsAndDaysSinceRegistration(registrationTime);

    return (
        <div>
            <p>Аккаунт зарегистрирован: {years > 0 ? `${years} год${years > 1 ? 'а' : ''} ` : ''}{days} дн. назад</p>
        </div>
    );
};

const PhoneNumberDisplay = ({ phoneNumber }) => {
    const formatPhoneNumber = (number) => {
        if (!number) {
            return 'неуказан';
        }

        const cleanedNumber = number.replace(/\D/g, '');

        let formattedNumber;
        if (cleanedNumber.startsWith('375')) {
            formattedNumber = cleanedNumber;
        } else if (cleanedNumber.startsWith('+')) {
            formattedNumber = '375' + cleanedNumber.slice(1);
        } else {
            formattedNumber = '375' + cleanedNumber;
        }

        if (formattedNumber.length === 12 || formattedNumber.length === 13) {
            return `+${formattedNumber}`;
        } else {
            return 'неправильный формат';
        }
    };
    return (
        <div>
            <p>Телефон: {formatPhoneNumber(phoneNumber)}</p>
        </div>
    );
};

function showMessage(success, text) {
    const myAccount = document.querySelector('.my_account');
    const message = document.createElement('div');
    message.className = success ? 'auth_success' : 'auth_error';
    message.innerText = text;
    myAccount.appendChild(message);
    setTimeout(() => {
        myAccount.removeChild(message);
    }, 2000);
}
