import { Link } from 'react-router-dom';

import images from '../components/ImportPaths/Imports';
import PriceClub from '../components/sections/PriceClub'

export default function ClubPage() {
    return (
        <div className="club_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Компьютерный клуб AVATAR в Бресте </p>
                    <h3>КЛУБ</h3>
                </div>
            </section>
            <section className="arena_page_content">
                <div className="arena_content">
                    <div className="arena_content_wrapper">
                        <div className="arena_list">
                            <div className="arena_block">
                            <img src={images.pin_2} />
                            <p>35 ПК, 1 PS5</p>
                            </div>
                        </div>
                        <div className="comp_wrapper">
                            <div className="comp_content">
                                <p>Первый клуб сети AVATAR в Бресте, который уже успел
                                зарекомендовать себя и покорить сердца наших постоянных
                                гостей.</p>
                            </div>
                            <div className="comp_address">
                                <a href="tel:+375292222214">+375292222214</a>
                                <p>г. Брест, ул. Пушкинская, 23</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="arena_page_titles">
                <div className="titles_wrapper">
                    <div className="titles_block">
                        <h4>Большой</h4>
                        <p>выбор еды и напитков</p>
                    </div>
                    <div className="titles_block">
                        <h4>Удобная</h4>
                        <p>локация</p>
                    </div>
                    <div className="titles_block">
                        <h4>Своя</h4>
                        <p>атмосфера</p>
                    </div>
                    <div className="titles_block">
                        <h4>Мощные</h4>
                        <p>компьютеры</p>
                    </div>
                    <div className="titles_block">
                        <h4>35</h4>
                        <p>машин</p>
                    </div>
                </div>
            </section>
            <h4 className="club_table_title">ЦЕНЫ</h4>
            <PriceClub/>
        </div>
    )
}
