import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../AdminAuth/AuthContext"; 

import images from '../components/ImportPaths/Imports';

export default function Auth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = (e) => {
        e.preventDefault();
        const isLoggedIn = login(email, password);

        if (isLoggedIn) {
            navigate('/create');
        } else {
            alert('Неверный логин или пароль. Попробуйте снова.');
        }
    };

    return (
        <div className="authorize">
            <div className="authorize_container">
                <div className="authorize_body">
                    <div className="authorize_form">
                        <div className="admin-production">
                        <a href="https://www.linkedin.com/company/blxseed-product/" target="_blank" rel="noopener noreferrer">
                            BLXSSED PRODUCT
                            <img src={images.cog} alt="Cog" />
                        </a>
                        </div>
                        <div className="authorize_form_wrap">
                            <h3>С ВОЗВРАЩЕНИЕМ</h3>
                            <h4>Пожалуйста, авторизируйтесь</h4>
                            <form onSubmit={handleLogin}>
                                <label>Логин</label>
                                <input 
                                    type="email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    required 
                                />
                                <label>Пароль</label>
                                <input 
                                    type="password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required 
                                />
                                <button type="submit">Войти</button>
                            </form>
                        </div>
                    </div>
                    <div className="authorize_preview">
                        <img src={images.logo} alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    );
}
