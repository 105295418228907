import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './components/AdminFooter';
import Sidebar from './components/AdminSidebar';
import images from '../components/ImportPaths/Imports';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Main() {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [file, setFile] = useState(null);
    const [posts, setPosts] = useState([]);
    const [popup, setPopup] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await updatePosts();
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleTitle = (value) => setTitle(value);
    const handleText = (value) => setText(value);
    const handleFile = (value) => setFile(value);

    const updatePosts = async () => {
        try {
            const response = await axios.get('/api/posts');
            if (Array.isArray(response.data)) {
                setPosts(response.data.reverse());
            } else {
                console.error('Expected an array but received:', response.data);
                setPosts([]);
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
            setPosts([]);
        }
    };

    const sendPost = async () => {
        const option = document.querySelector('select').value;
        const formData = new FormData();
        formData.append('title', title);
        if (file && file.type !== "Buffer") {
            formData.append('cover', file);
        }
        formData.append('content', text);
        formData.append('place_type', option);

        try {
            if (edit) {
                await axios.patch(`/api/post/${edit}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                await axios.post(`/api/post`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
            handleTitle('');
            handleFile(null);
            handleText('');
            updatePosts();
            setPopup(false);
            setEdit(null);
        } catch (error) {
            console.error('Error sending post:', error);
        }
    };

    const deletePost = async (item) => {
        try {
            await axios.delete(`/api/post/${item.id}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            updatePosts();
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const addEmoji = (emoji) => {
        const cursorPosition = text.length;
        const newText = `${text.slice(0, cursorPosition)}${emoji.native}${text.slice(cursorPosition)}`;
        setText(newText);
        setShowEmojiPicker(false);
    };

    const closePopup = (e) => {
        e.stopPropagation();
        if (edit) {
            handleTitle('');
            handleFile(null);
            handleText('');
        }
        setPopup(false);
        setEdit(null);
    };

    const openPopup = () => {
        setPopup(true);
    };

    const editPost = async (item) => {
        openPopup();
        setEdit(item.id);
        handleTitle(item.title);
        handleFile(item.cover); 
        handleText(item.content);
        document.querySelector('select').value = item.place_type;
    };

    return (
        <div className="create-page">
            <Sidebar />
            <div className="create_wrapper">
                <div className="posts_info">
                    <p>Всего постов: {posts.length}</p>
                </div>
                <div className="posts_wrapper">
                    {!posts.length ? (
                        <div className="posts_empty">Нет постов</div>
                    ) : null}

                    <div className="posts_inner">
                        {posts.map((item) => {
                            const timestamp = item.currentTimestamp;
                            let formattedDate = 'Неизвестная дата';

                            if (timestamp) {
                                const date = new Date(timestamp);
                                if (!isNaN(date.getTime())) {
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    formattedDate = `${day}/${month}/${year}`;
                                }
                            }

                            return (
                                <div className="news_block" key={item.id}>
                                    <Link className="news_link_wrap" to={`/news/${item.id}`}>
                                        {item.cover && (
                                            <img
                                                src={`data:image/${item.cover.type};base64,${item.cover.image}`}
                                                alt={item.title}
                                            />
                                        )}
                                    </Link>
                                    <div className="news_block_content">
                                        <h5>{item.title}</h5>
                                        <p>{item.content}</p>
                                        <div>
                                            <span>{formattedDate}</span><br />
                                            <span>{item.place_type}</span>
                                        </div>
                                    </div>
                                    <img onClick={() => editPost(item)} className="edit_btn" src={images.edit_pic} alt="Edit" />
                                    <img onClick={() => deletePost(item)} className="delete_btn" src={images.trash} alt="Delete" />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <button onClick={openPopup} className="create-post-button">
                    Создать пост
                </button>
            </div>
            <div onClick={(e) => closePopup(e)} className={popup ? 'popup-screen show' : 'popup-screen hide'}>
                <div onClick={(e) => e.stopPropagation()} className="popup-body">
                    {showEmojiPicker && (
                        <div className="emoji-picker-container">
                            <Picker data={data} onEmojiSelect={addEmoji} />
                        </div>
                    )}
                    <span className="close-popup" onClick={(e) => closePopup(e)}>+</span>
                    <div className="create-post-form">
                        <input
                            type="text"
                            value={title}
                            onChange={(el) => handleTitle(el.target.value)}
                            placeholder="Заголовок"
                            className="create-post-title form-input"
                            id="post-title"
                        />
                        <div className="create-post-cover-wrapper">
                            <label htmlFor="post-cover" className="select-cover-btn">
                                Выбрать файл
                            </label>
                            <input
                                type="file"
                                onChange={(el) => handleFile(el.target.files[0])}
                                className="minimal-file-input"
                                id="post-cover"
                                accept="image/png, image/jpeg"
                            />
                            {file && file instanceof File && (
                                <div className="cover-info">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt="Cover Preview"
                                    />
                                    <span>{file.name}</span>
                                </div>  
                            )}
                            {file && file.type === "Buffer" && (
                                <div className="cover-info">
                                    <img src={`data:image/jpeg;base64,${file.image}`} alt="Cover Preview" />
                                </div>
                            )}
                        </div>
                        <div className="create-post-text-wrapper">
                            <textarea
                                placeholder="Описание"
                                value={text}
                                onChange={(el) => handleText(el.target.value)}
                                className="text-area create-post-text"
                                id="post-text"
                            />
                            <div className="create-post-control">
                                <select id="post-type" className="form-select">
                                    <option value="ARENA" selected>ARENA</option>
                                    <option value="CYBERCLUB">CYBERCLUB</option>
                                </select>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                        className="emoji-button"
                                    >
                                        <img src={images.emo} alt="Emoji" />
                                    </button>
                                    <button onClick={sendPost} className="submit-button">
                                        <img src={images.send} alt="Send" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
