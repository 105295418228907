import { Link } from 'react-router-dom';

import images from '../components/ImportPaths/Imports';

export default function About() {
    return (
        <div className="contacts_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Забронировать </p>
                    <h3>НАШИ КОНТАКТЫ</h3>
                </div>
            </section>
            <section className="contacts_map">
                <div className="map_wrapper">
                    <div className="map">
                    <div className="map_address_block mobile">
                            <h5>АРЕНА</h5>
                            <p><a href="tel:+375295485555">+375 (29) 548 55 55</a><br/>
                            г. Брест, ул. Советская 71/2</p>
                            <div className="map_block_links">
                                <a href="https://www.instagram.com/avatar.arena.brest/">
                                    <img src={images.inst} />
                                </a>
                                <a href="https://t.me/avatararenabrest"> <img src={images.tg} /> </a>
                            </div>
                            <a className="link" href="https://avatar.restoplace.ws/">Забронировать</a>
                            <hr/>
                        </div>
                    <div style={{position:'relative',overflow:'hidden'}}><a href="https://yandex.by/maps/org/avatar/214546258464/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'0px'}}>Avatar</a><a href="https://yandex.by/maps/153/brest/category/computer_club/97550856506/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'14px'}}>Компьютерный клуб в Бресте</a><a href="https://yandex.by/maps/153/brest/category/gaming_club/184106332/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'28px'}}>Игровой клуб в Бресте</a><iframe src="https://yandex.by/map-widget/v1/?ll=23.694569%2C52.090409&mode=search&oid=214546258464&ol=biz&z=18.1" width="560" height="400" frameborder="1" allowfullscreen="true" style={{position:'relative'}}></iframe></div>
                    <div className="map_address_block mobile">
                            <h5>КЛУБ</h5>
                            <p><a href="tel:+375292222214">+375 (29) 222 22 14</a><br/>
                                г. Брест, ул. Пушкинская 23</p>
                            <div className="map_block_links">
                                <a href="https://www.instagram.com/avatar.cyberclub/">
                                    <img src={images.inst} />
                                </a>
                                <a href="https://t.me/avatar_cyberclub"> <img src={images.tg} /> </a>
                            </div>
                            <a className="link" href="https://avatar.restoplace.ws/">Забронировать</a>
                            <hr/>
                        </div>
                    <div style={{position:'relative',overflow:'hidden'}}><a href="https://yandex.by/maps/org/avatar/48765637541/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'0px'}}>Avatar</a><a href="https://yandex.by/maps/153/brest/category/internet_cafe/184105740/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'14px'}}>Интернет-кафе в Бресте</a><a href="https://yandex.by/maps/153/brest/category/computer_club/97550856506/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'28px'}}>Компьютерный клуб в Бресте</a><iframe src="https://yandex.by/map-widget/v1/?ll=23.690667%2C52.095553&mode=poi&poi%5Bpoint%5D=23.690527%2C52.095628&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D48765637541&z=21" frameborder="1" allowfullscreen="true" style={{position:'relative'}}></iframe></div>
                    </div>
                    <div className="map_address pc">
                        <div className="map_address_block">
                            <h5>АРЕНА</h5>
                            <p><a href="tel:+375295485555">+375 (29) 548 55 55</a><br/>
                            г. Брест, ул. Советская 71/2</p>
                            <div className="map_block_links">
                                <a href="https://www.instagram.com/avatar.arena.brest/">
                                    <img src={images.inst} />
                                </a>
                                <a href="https://t.me/avatararenabrest"> <img src={images.tg} /> </a>
                            </div>
                            <a className="link" href="https://avatar.restoplace.ws/">Забронировать</a>
                            <hr/>
                        </div>
                        <div className="map_address_block">
                            <h5>КЛУБ</h5>
                            <p><a href="tel:+375292222214">+375 (29) 222 22 14</a><br/>
                                г. Брест, ул. Пушкинская 23</p>
                            <div className="map_block_links">
                                <a href="https://www.instagram.com/avatar.cyberclub/">
                                    <img src={images.inst} />
                                </a>
                                <a href="https://t.me/avatar_cyberclub"> <img src={images.tg} /> </a>
                            </div>
                            <a className="link" href="https://avatar.restoplace.ws/">Забронировать</a>
                            <hr/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
