import { Link } from 'react-router-dom';

import News from '../components/sections/News';

export default function Main() {
    return (
        <div className="latest_news_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Новости AVATAR </p>
                    <h3>ПОСЛЕДНИЕ<br/>НОВОСТИ</h3>
                </div>
            </section>
            <section className="latest_news_wrapper">
                <News />
            </section>
        </div>
    );
}
