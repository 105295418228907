import React from 'react';
import { useLocation } from 'react-router-dom';

const ErrorPage = () => {
    const location = useLocation();
  
    const errorCode = location.state?.errorCode || '404';
    const errorMessage = location.state?.message || 'Извините ↓↓↓';
    const pageNotFound = 'Данной страницы не существует';

    return (
            <div className="error-page">
                <div className='error-margin-bottom'>
                    <div className='error-rail-animation'>
                            <div className='error-code-wrapper'>
                                <div className='error-rail_item'>
                                    <h1 className="error-code">{errorCode}</h1>
                                    <br />
                                    <h1 className="error-code">{errorMessage}</h1>
                                    <span className="error-message">{pageNotFound}</span>
                                </div>
                            </div>
                    </div>
                </div>      
            <a className="home-link" href="/">Вернуться на главную</a>
        </div>
    );
};

export default ErrorPage;
