import React from 'react'

import images from '../../components/ImportPaths/Imports';

export default function Footer() {
  return (
    <div onClick={toggleSidebar} class="admin-sidebar">
        <div class="sidebar-inner">
          <div>
            <img src={images.logo_com} alt="logo_com" />
          </div>
          <div>
            <a href="https://t.me/BLXSSEDPRODUCTBOT" target="_blank">
              <img src={images.linktobot} alt="Log" />
            </a>
            <a href="/admin">
              <img src={images.log} alt="Log" />
            </a>
          </div>
        </div>
    </div>
  )
}

function toggleSidebar(){
    const sidebar = document.querySelector('.admin-sidebar');
    sidebar.classList.toggle('open');
}