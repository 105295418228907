import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import images from '../components/ImportPaths/Imports';

export default function Header() {
    const [userId, setUserId] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let user_id = sessionStorage.getItem('userId');
        if (user_id) {
            setUserId(user_id);
        }
    }, []);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);

    const toggleMenu = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div class="header">
        <header>
            <div className="header_wrapper">
                <div className="logo">
                    <Link to="/">
                        <img src={images.logo_com} alt="Логотип" />
                    </Link>
                </div>
                <nav>
                    <Link to="/arena">Арена</Link>
                    <Link to="/club">Клуб</Link>
                    <Link to="/price">Цены</Link>
                    <Link to="/games">Игры</Link>
                    <Link to="/news">Новости</Link>
                    <Link to="/contacts">Забронировать</Link>
                    <Link to="/tournament">Турниры</Link>
                    {userId && <Link to="/my">Кабинет</Link>}
                    {!userId && <Link to="/auth">Войти</Link>}
                </nav>
                <img className="time" src={images.time} alt="Время" />
                <div className="burger" onClick={toggleMenu}>
                    <img src={images.menu} alt="Меню" />
                </div>
            </div>
        </header>
        <div className={`mobile_burger ${isMenuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <img onClick={toggleMenu} src={images.menu} alt="Меню" />
            {isMenuOpen && (
                <nav>
                    <Link to="/arena" onClick={toggleMenu}>Арена</Link>
                    <Link to="/club" onClick={toggleMenu}>Клуб</Link>
                    <Link to="/price" onClick={toggleMenu}>Цены</Link>
                    <Link to="/games" onClick={toggleMenu}>Игры</Link>
                    <Link to="/news" onClick={toggleMenu}>Новости</Link>
                    <Link to="/contacts" onClick={toggleMenu}>Забронировать</Link>
                    <Link to="/tournament" onClick={toggleMenu}>Турниры</Link>
                    {userId && <Link to="/my" onClick={toggleMenu}>Кабинет</Link>}
                    {!userId && <Link to="/auth" onClick={toggleMenu}>Войти</Link>}
                </nav>
            )}
        </div>
        </div>

    );
}
