import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import images from '../ImportPaths/Imports';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function News({ showPagination = true }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 4;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/posts');
                setPosts(response.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return (
        <section className="news">
            <img className="decor" src={images.decor} alt="decor" />
            <h3>ПОСЛЕДНИЕ НОВОСТИ</h3>
            <div className="news_wrap">
                <img className="loading" src={images.loading_gif} alt="Loading..." />
            </div>
        </section>
    );

    const sortedPosts = [...posts].sort((a, b) => {
        return new Date(b.currentTimestamp) - new Date(a.currentTimestamp);
    });

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(sortedPosts.length / postsPerPage);

    return (
        <section className="news">
            <img className="decor" src={images.decor} alt="decor" />
            <h3>ПОСЛЕДНИЕ НОВОСТИ</h3>
            <div className="news_wrap">
                {currentPosts.map((item) => {
                    const timestamp = item.currentTimestamp;
                    let formattedDate = 'Неизвестная дата';

                    if (timestamp) {
                        const date = new Date(timestamp);
                        if (!isNaN(date.getTime())) {
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            formattedDate = `${day}/${month}/${year}`;
                        }
                    }

                    return (
                        <div className="news_block" key={item.id}>
                            <Link className="news_link_wrap" to={`/news/${item.id}`}>
                                {item.cover ? (
                                    <img
                                        src={`data:image/${item.cover.type};base64,${item.cover.image}`}
                                        alt={item.title}
                                    />
                                ) : (
                                    <img src={images.defaultCover} alt="Default Cover" />
                                )}
                            </Link>
                            <div className="news_block_content">
                                <h5>{item.title}</h5>
                                <p>{item.content}</p>
                                <div>
                                    <span>{formattedDate}</span><br />
                                    <span>{item.place_type}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <Link className="link_to_news" to="/news">ВСЕ НОВОСТИ</Link>
            {showPagination && (
                <div className="pagination">
                    <button
                        className="prev-button"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        &#8592;
                    </button>
                    <button
                        className="page-number"
                        disabled
                    >
                        {currentPage}
                    </button>
                    {currentPage < totalPages && (
                        <button
                            className="next-button"
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                            &#8594;
                        </button>
                    )}
                </div>
            )}
        </section>
    );
}
