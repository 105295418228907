import { Link } from 'react-router-dom';

import images from '../components/ImportPaths/Imports';

export default function Games() {
    return (
        <div className="games_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Игры в AVATAR </p>
                    <h3>ИГРЫ</h3>
                </div>
            </section>
        <section className="games_list">
            <img className="decor" src={images.decor_3} />
            <img className="decor" src={images.decor_3} />
            <p>Только лицензионные игры с возможностью подключения вашего аккаунта</p>
            <div className="games_list_wrapper">
                <img src={images.game_cs} />
                <img src={images.game_dota} />
                <img src={images.game_pubg} />
                <img src={images.game_fort} />
                <img src={images.game_val} />
                <img src={images.game_gta} />
                <img src={images.game_cod} />
                <img src={images.game_rust} />
                <img src={images.game_wow} />
                <img src={images.game_hell} />
            </div>
        </section>
        </div>
    )
}
