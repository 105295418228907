import { Link } from 'react-router-dom';

import images from '../components/ImportPaths/Imports';

export default function TournamentPage() {
    return (
        <div className="tournament_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Турниры в AVATAR</p>
                    <h3>ТУРНИРЫ</h3>
                </div>
            </section>
            <section className="tournament_page_titles">
            <img className="decor" src={images.decor} />
            <img className="decor_2" src={images.decor_3} />
                <div className="tr_titles_wrapper">
                    <div className="tr_titles_block">
                        <h4>Актуальные</h4>
                        <p>игры</p>
                        <h4>Техническая</h4>
                        <p>поддержка</p>
                    </div>
                    <div className="tr_titles_wrapper">
                    <div className="tr_titles_block">
                        <h4>Призы и подарки</h4>
                        <p>каждое воскресенье</p>
                        <h4>Быстрый</h4>
                        <p>сервис</p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="arena_page_content">
                <div className="tour_page_content">
                    <div className="tournament_content_wrapper">
                        <div className="tour_wrapper">
                            <div className="tour_content">
                                <p>Турниры AVATAR — крутые киберспорт ивенты, где команды сражаются 
                                    за призы и славу. Участники показывают свои навыки в увлекательных матчах. 
                                    Присоединяйтесь к нам в атмосферу соревнований!</p>
                            </div>
                            <div className="link_to_bot">
                                <a href="https://t.me/AVTournamentsBot">РЕГИСТРАЦИЯ НА ТУРНИРЫ</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tr_zones">
                <img className="decor" src={images.decor} />
                <div className="zones_wrapper">
                    <h3>НАЙДИ СВОЮ МЕТУ</h3>
                    <div className="tr_zones_blocks_wrapper">
                        <div className="tr_zones_block">
                            <img src={images.tour_1} />
                        </div>
                        <div className="tr_zones_block">
                            <img src={images.tour_2} />
                        </div>
                        <div className="tr_zones_block">
                            <img src={images.tour_3} />
                        </div>
                    </div>
                </div>
            </section>
            <section className='photo_zone'>
            <div className="photo_info">
                <h5>КАЖДЫЙ ТУРНИР ПРОВОДИТСЯ ФОТОСЕССИЯ ОТ ПРОФЕССИОНАЛЬНОГО ФОТОГРАФА.</h5>
            </div>
            <div className="photo_link">
                    <a href="https://shmatovaphoto.ru/disk/03-11-2024-cs-2-tournament-global-03-11-pl97dk">
                        ФОТООТЧЕТЫ</a> 
                    </div>
            </section>
        </div>
    )
}
